import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import './Detail.css';
import Img1 from "../images/detail1.png";
import Img2 from "../images/detail2.png";
import Img3 from "../images/detail3.png";
import Img4 from "../images/detail4.png";
import Img5 from "../images/detail5.png";
import Img6 from "../images/detail6.png";

class NavBar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            number:0,
            array:[
                {
                    img:Img1,
                    name:'【小锅万物】',
                    content:'随机人群测试，定位核心人群，科学合理的问卷预设数据分析帮助品牌方发掘产品问题及优化方向',
                    substr:['基于超过80%的调研反馈率获取的大量体验数据','全方位的产品优化建议']
                },
                {
                   img:Img2,
                   name:'【醒肌密】',
                   content:'基于同品类派送经验给出人群优化建议，精准派发核心用户，分析核心用户特征并挖掘业务增长点',
                   substr:['超高的私域引流沉淀率','发现男性用户增长点，并获得公域验证']
                },
                {
                    img:Img3,
                    name:'【金活医药】',
                    content:'销售TA及可能性TA画像相结合，采取正相关匹配定点派送的形式进行样品派发；分析反馈数据帮助品牌发现成熟产品更多的营销可能性',
                    substr:['售卖产品规格优化建议，取得优秀转化','市场方向及C端营销角度建议']
                },
                {
                    img:Img4,
                    name:'【21cake】',
                    content:'根据新品特点，定制问卷的形式来聚焦新品受众，通过产品派样测试的形式收集反馈进行数据分析，并且对人群进行分析，明确核心受众特征',
                    substr:['1:1的核心用户私域沉淀','产品的口碑传播及多方面优化建议']
                },
                {
                    img:Img5,
                    name:'【偶爱你】',
                    content:'通过样品派送体验的形式给到用户进行试用，培养用户习惯。并分析好评人群特征，进一步聚焦核心用户',
                    substr:['产品销售量显著提升','核心人群画像以及营销角度建议']
                },
                {
                    img:Img6,
                    name:'【ElectroX】',
                    content:'根据人群画像进行相关性匹配派送，结合新品期待预设问题和消费者共创产品，并且收集用户反馈分析核心人群的特征和定位',
                    substr:['新品研发方向','线下销售推广思路']
                }
            ]
        };
    }

    componentDidMount(){
        this.setState({
            number:parseInt(window.location.href.split('detail_')[1])-1
        })
          //console.log(typeof parseInt(window.location.href.split('detail_')[1]))
    }

    render(){
        return(
            <div className="detail">
                <img className="title_img" src={this.state.array[this.state.number].img}/>
                <div className="detail-contain">
                    <div className='name'>{this.state.array[this.state.number].name}</div>
                    <div className="content">
                      <div className='title'>服务方案:</div>
                      <div className='substr'>{this.state.array[this.state.number].content}</div>
                      <div className='title'>关键产出:</div>
                      <div className='substr'>
                          <div><span></span>{this.state.array[this.state.number].substr[0]}</div>
                          <div><span></span>{this.state.array[this.state.number].substr[1]}</div>
                      </div>
                    </div>
                </div>
            </div>
        )
    }
}

NavBar.propTypes = {

}

export default NavBar

