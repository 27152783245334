import React from 'react';
import Title from '../images/main_title.png';
import MBbackground from '../images/matchbox_background.png';
import Circle from '../images/circle.png';
import './Home.css';
import { Link } from "react-router-dom";
import Slider from "react-slick";




class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getImgs: [],
      getPhoneImgs: [],
      banner: '',
      banner2: '',
      cem_basic: '',
      cem_comment: '',
      cem_analysis: '',
      partner_banner: '',
      settings: {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        initialSlide: 0
      }
    };
  }


  move = () => {
    if (this.oUl.offsetLeft < -this.oUl.offsetWidth / this.speed) {
      this.oUl.style.left = '0'
    }
    if (this.oUl.offsetLeft > 0) {
      this.oUl.style.left = -this.oUl.offsetWidth / this.speed + 'px';
    }
    this.oUl.style.left = this.oUl.offsetLeft - 2 + 'px';
  }

  handleImageScroll() {

    this.oUl = document.getElementsByClassName('image-container')[0].getElementsByTagName('ul')[0];
    this.oLis = document.getElementsByClassName('image-container')[0].getElementsByTagName('li');
    this.oUl.innerHTML = this.oUl.innerHTML + this.oUl.innerHTML;
    this.oUl.style.width = this.oLis[0].offsetWidth * this.oLis.length + 'px';
    this.speed = 2;
    this.interval = setInterval(this.move, 30);

  }

  startImageScroll = () => {

    this.interval = setInterval(this.move, 30);

  }

  endImageScroll = () => {
    clearInterval(this.interval);
  }


  importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item).default; });
    return images;
  }
  reload() {
    setTimeout(() => {
      window.location.reload()
    }, 10)
  }
  componentDidMount() {
    const images = this.importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));
    let getImgs = []
    let getPhoneImgs = []
    let banner = images['banner.jpeg']
    let banner2 = images['banner2.png']
    let cem_basic = images['cem_basic.png']
    let cem_comment = images['cem_comment.png']
    let cem_analysis = images['cem_analysis.png']
    let partner_banner = images['partner_banner.png']
    for (let i = 1; i <= 18; i++) {
      getImgs.push(images[i + '.png'])
      getPhoneImgs.push(images['phone' + i + '.png'])
    }
    this.setState({
      getImgs: getImgs,
      getPhoneImgs: getPhoneImgs,
      banner: banner,
      banner2: banner2,
      cem_basic: cem_basic,
      cem_comment: cem_comment,
      cem_analysis: cem_analysis,
      partner_banner: partner_banner,
    }, () => {
      this.handleImageScroll();
    })

  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    return (
      <>
        <div className="banner">
          <img className="banner-background displayNoneBlockPC" src={this.state.banner} />
          <img className="banner-background displayNonePhone" src={this.state.banner2} />
          <div className="banner-title">
            <span></span><img src={Title} />
          </div>
          <div className="banner-subtitle fontSize">
            连接品牌方和消费者的体验共创平台
          </div>
        </div>
        <div className="container">
          <div className="sec-title">
            <img src={MBbackground} />
            <div className="center title-font"> 共创特色</div>
          </div>
          <div className="sec-paragraph">
            <p>“货找人”的体验式营销，实现精准派样、</p>
            <p>品牌推广及消费者体验测试，高效提升新品</p>
            <p>开发和投放效率，从数据中发掘新的增长机会点</p>
          </div>
          <ul className="list-template summary displayNonePC">
            <li className="summary-item">
              <div className="summary-item-title">
                <div className="num">01</div>
                <div className="text">精准匹配</div>
              </div>
              <div className="cont">
                多维度（年龄、性别、职业、消费偏好等）用户真实标签供品牌选择，实现精准派送。
              </div>
            </li>
            <li className="summary-item">
              <div className="summary-item-title">
                <div className="num">02</div>
                <div className="text">体验数据</div>
              </div>
              <div className="cont">
                预设产品问卷评论及用户自主填写评论形成完整反馈信息，品类数据横向比对，实现营销敏捷迭代。
              </div>
            </li>
            <li className="summary-item">
              <div className="summary-item-title">
                <div className="num">03</div>
                <div className="text">流量落地</div>
              </div>
              <div className="cont">
                一键导流，引导用户在试用产品后，在品牌方的公众号、小程序或其他页面，实现流量落地，销售转化。
              </div>
            </li>
          </ul>
          <ul className="list-template summary displayNonePhone">
            <li className="summary-item">
              <div className="summary-item-title">
                <div className="num">01</div>
              </div>
              <div className="cont">
                <div className="text">精准匹配</div>
                多维度（年龄、性别、职业、消费偏好等）用户真实标签供品牌选择，实现精准派送。
              </div>
            </li>
            <li className="summary-item">
              <div className="summary-item-title">
                <div className="num">02</div>
              </div>
              <div className="cont">
                <div className="text">体验数据</div>
                预设产品问卷评论及用户自主填写评论形成完整反馈信息，品类数据横向比对，实现营销敏捷迭代。
              </div>
            </li>
            <li className="summary-item">
              <div className="summary-item-title">
                <div className="num">03</div>
              </div>
              <div className="cont">
                <div className="text">流量落地</div>
                一键导流，引导用户在试用产品后，在品牌方的公众号、小程序或其他页面，实现流量落地，销售转化。
              </div>
            </li>
          </ul>

          {/*<lu className="list-template circle">*/}
          {/*  <li className="circle-container">*/}
          {/*    <div className="circle-item">*/}
          {/*      <img src={Circle} />*/}
          {/*      <div className="center circle-font">10W+</div>*/}
          {/*    </div>*/}
          {/*    <div className="circle-annotation">*/}
          {/*      精选用户*/}
          {/*    </div>*/}
          {/*  </li>*/}
          {/*  <li className="circle-container">*/}
          {/*    <div className="circle-item">*/}
          {/*      <img src={Circle} />*/}
          {/*      <div className="center circle-font"> &gt;75%</div>*/}
          {/*    </div>*/}
          {/*    <div className="circle-annotation">*/}
          {/*      体验反馈率*/}
          {/*    </div>*/}
          {/*  </li>*/}
          {/*  <li className="circle-container">*/}
          {/*    <div className="circle-item">*/}
          {/*      <img src={Circle} />*/}
          {/*      <div className="center circle-font"> ~8% </div>*/}
          {/*    </div>*/}
          {/*    <div className="circle-annotation">*/}
          {/*      销售转化*/}
          {/*    </div>*/}
          {/*  </li>*/}
          {/*  <li className="circle-container">*/}
          {/*    <div className="circle-item">*/}
          {/*      <img src={Circle} />*/}
          {/*      <div className="center circle-font">40+</div>*/}
          {/*    </div>*/}
          {/*    <div className="circle-annotation">*/}
          {/*      合作品牌*/}
          {/*    </div>*/}
          {/*  </li>*/}

          {/*</lu>*/}


          <div className="sec-title">
            <img src={MBbackground} />
            <div className="center title-font"> 心盒共创CEM</div>
          </div>
          <div className="sec-text">
            <p>品牌方可实时监测产品的派送进度、收货情况及用户反馈</p>
          </div>

          <lu className="list-template cem-list displayNonePC">
            <li className="cem-item">
              <img src={this.state.cem_basic} />
              <div> 地域、性别、职业、年龄看板 </div>
            </li>
            <li className="cem-item">
              <img src={this.state.cem_comment} />
              <div> 反馈、词义分析一体化看板 </div>
            </li>
            <li className="cem-item">
              <img src={this.state.cem_analysis} />
              <div> 深度分析助手 </div>
            </li>
          </lu>
          <div className="btn displayNonePC"><a href='http://cem.xinhekeji.net'>了解更多</a></div>

          <div className="marginTop4 displayNonePhone">
            <Slider {...this.state.settings} className="">
              <li className="cem-item">
                <img src={this.state.cem_basic} />
                <div> 地域、性别、职业、年龄看板 </div>
              </li>
              <li className="cem-item">
                <img src={this.state.cem_comment} />
                <div> 反馈、词义分析一体化看板 </div>
              </li>
              <li className="cem-item">
                <img src={this.state.cem_analysis} />
                <div> 深度分析助手 </div>
              </li>
            </Slider>
          </div>


          <div className="sec-title">
            <img src={MBbackground} />
            <div className="center title-font"> 合作伙伴</div>
          </div>
          <div className="sec-text">
            <p>消费者体验共创平台，基于数据帮助品牌发掘新的增长机会</p>
          </div>
          {/* <ul className="partner">
              {
                this.state.getPhoneImgs.map((item,index)=>{
                  return <li key={'img'+index}><img src={item} /></li>
                })
              }
            </ul> */}

          <div className="cloud-container">
            <div className="image-container" onMouseOver={this.endImageScroll} onMouseOut={this.startImageScroll}>

              <ul>
                <li>
                  <img src={this.state.partner_banner} alt="" />
                </li>
                <li>
                  <img src={this.state.partner_banner} alt="" />
                </li>
                <li>
                  <img src={this.state.partner_banner} alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="btn">
            <Link to='/Example' onClick={this.reload}>了解更多</Link>
          </div>

        </div>
      </>
    )
  }
}

Home.propTypes = {}

export default Home

