import React, {useState} from 'react';
import Slider from 'react-slick';
// import Banner from '../images/about_us.png';
import CompPic from '../images/office_pic.png';
import HomeIcon from '../images/home.png';
import btn from '../images/btm.png';
import './AboutUs.css';
import {Link} from "react-router-dom";




class AboutUs extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      banner:'',
      banner2:'',
      settings:{
        dots: true,
        infinite: true,
        autoplay:true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        slidesPerRow: 1,
        initialSlide: 0
      }
    };
  }

  setHref(id) {
    this.setState({
      itemStyle:id
    })
    document.documentElement.scrollTop = document.getElementById(id).offsetTop-
    document.getElementsByClassName('aboutUs-nav')[0].offsetHeight -
    document.getElementsByClassName('navbar-container')[0].offsetHeight
  }

  importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item).default; });
    return images;
  }
  goHome(){
    window.location.reload()
  }
  componentDidMount(){
    // console.log(this.importAll)
    const images = this.importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));
    let getImgs = []
    let banner = images['about_us.jpeg']
    let banner2 = images['about_us2.png']
    for(let i=1;i<=18;i++){
      getImgs.push(images[i+'.png'])
    }
    this.setState({
      getImgs:getImgs,
      banner:banner,
      banner2:banner2
    })
  }

  render(){
    return(
        <>
          <div className="banner">
            <img className="banner-background displayNoneBlockPC" src={this.state.banner} />
            <img className="banner-background displayNonePhone" src={this.state.banner2} />
            <div className="aboutus-banner-subtitle-en">
              About Us
            </div>
            <div className="aboutus-banner-subtitle-cn">
              关于我们
            </div>
          </div>
          <div className='aboutUs-nav displayNoneBlockPC'>
            <div className='nav-container'>
              <div className='left'>
                <img src={HomeIcon}/>
                <span onClick={this.goHome}><Link to='/'>首页</Link></span>
                <span>&gt;</span>
                <span>关于我们</span>
              </div>
              <div className='right'>
                <span className={this.state.itemStyle=='1F'?'active':''} onClick={this.setHref.bind(this,'1F')}>企业简介</span>
                <span className={this.state.itemStyle=='2F'?'active':''} onClick={this.setHref.bind(this,'2F')}>企业文化</span>
                <span className={this.state.itemStyle=='3F'?'active':''} onClick={this.setHref.bind(this,'3F')}>团队生活</span>
              </div>
            </div>
          </div>
          {/*<hr className="solid-line"></hr> */}
          <div className="aboutus-container">
            <div className="sec-title" id="1F">
              <div className="text"> 企业简介</div>
              <div className='line displayNoneBlockPC'>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="sec-text displayNonePC">
              <div className="left">
                <div className="text-title">
                  上海心盒科技有限公司
                </div>
                <div className="text-paragraph">
                  <p>
                    上海心盒科技有限公司创立于2020年，一个来自宝洁、联合利华、亿滋、贝恩等知名企业，
                    具有丰富的品牌操盘和咨询服务经验的团队，看到消费品牌在营销的过程中，有大量的消费者反馈数据被埋没、被流失。
                  </p>
                  <p>
                    他们创造了心愿盒，一座链接品牌与消费者的桥梁，一个品牌与消费者互相尊重，互相聆听对方声音的乌托邦。
                  </p>
                  <p>
                    这段旅程的开端很艰难，但是他们发现，有越来越多志同道合的品牌方、消费者、小伙伴和他们走到了一起。
                  </p>
                  <p>
                    因为聆听很难得，尊重很珍贵。
                  </p>
                  <p>
                    希望您能在这里实现自己的心愿，并把心愿带给到更多的人。
                  </p>
                </div>
              </div>
              <img className="right" src={CompPic} />
            </div>
            <div className="sec-text displayNonePhone">
              <div className="text-paragraph">
                <p>
                  上海心盒科技有限公司创立于2020年，一个来自宝洁、联合利华、亿滋、贝恩等知名企业，
                  具有丰富的品牌操盘和咨询服务经验的团队，看到消费品牌在营销的过程中，有大量的消费者反馈数据被埋没、被流失。
                </p>
                <p>
                  他们创造了心愿盒，一座链接品牌与消费者的桥梁，一个品牌与消费者互相尊重，互相聆听对方声音的乌托邦。
                </p>
                <p>
                  这段旅程的开端很艰难，但是他们发现，有越来越多志同道合的品牌方、消费者、小伙伴和他们走到了一起。
                </p>
                <p>
                  因为聆听很难得，尊重很珍贵。
                </p>
                <p>
                  希望您能在这里实现自己的心愿，并把心愿带给到更多的人。
                </p>
              </div>
            </div>


            <div className="sec-title" id="2F">
              <div className="text"> 企业文化</div>
              <div className='line displayNoneBlockPC'>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <ul className="list-template culture">
              <li className="culture-item">
                <div className='item-bg bird'></div>
                <div className="culture-title">
                  企业愿景
                </div>
                <img src={btn} className='aboutBtn displayNonePhone'/>
                <div className="culture-des">
                  <p>
                    传递每一份惊喜，聆听每一个声音
                  </p>
                </div>
              </li>
              <li className="culture-item">
                <div className='item-bg goal'></div>
                <div className="culture-title">
                  企业使命
                </div>
                <img src={btn} className='aboutBtn displayNonePhone'/>
                <div className="culture-des">
                  <p>
                    构建品牌和消费者沟通的桥梁，为社会带来更多更好的产品
                  </p>
                </div>
              </li>
              <li className="culture-item">
                <div className='item-bg compass'></div>
                <div className="culture-title">
                  首要原则
                </div>
                <img src={btn} className='aboutBtn displayNonePhone'/>
                <div className="culture-des">
                  <p>
                    尽自身最大努力，服务好每一个信任我们的人
                  </p>
                </div>
              </li>
              <li className="culture-item">
                <div className='item-bg teach'></div>
                <div className="culture-title">
                  首要目标
                </div>
                <img src={btn} className='aboutBtn displayNonePhone'/>
                <div className="culture-des">
                  <p>
                    成为人与品牌最好的孵化器
                  </p>
                </div>
              </li>

            </ul>

            <div className="sec-title" id="3F">
              <div className="text"> 团队生活</div>
              <div className='line displayNoneBlockPC'>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>

            <Slider {...this.state.settings} className="team-life">
              <div className='team-background team_pic1'></div>
              <div className='team-background team_pic2'></div>
              <div className='team-background team_pic3'></div>
              <div className='team-background team_pic4'></div>
              <div className='team-background team_pic5'></div>
              <div className='team-background team_pic6'></div>
            </Slider>

          </div>

        </>
    )
  }
}

AboutUs.propTypes = {}

export default AboutUs






// function importAll(r) {
//   let images = {};
//   r.keys().map((item, index) => { images[item.replace('./', '')] = r(item).default; });
//   return images;
// }
//
// const images = importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));


// function AboutUs() {
//   const settings = {
//     dots: true,
//     infinite: true,
//     autoplay:true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     slidesPerRow: 1,
//     initialSlide: 10
//   };
//
//     const [itemStyle,setItemStyle] = useState('1F');
//
//     function setHref(id) {
//       setItemStyle(id)
//       document.documentElement.scrollTop = document.getElementById(id).offsetTop-
//       document.getElementsByClassName('aboutUs-nav')[0].offsetHeight -
//       document.getElementsByClassName('navbar-container')[0].offsetHeight
//     }
//
//     return (
//         <>
//         <div className="banner">
//           <img className="banner-background" src={Banner} />
//           <div className="aboutus-banner-subtitle-en">
//             About Us
//           </div>
//           <div className="aboutus-banner-subtitle-cn">
//             关于我们
//           </div>
//         </div>
//         <div className='aboutUs-nav'>
//           <div className='nav-container'>
//             <div className='left'>
//               <img src={HomeIcon}/>
//               <span><Link to='/'>首页</Link></span>
//               <span>&gt;</span>
//               <span>关于我们</span>
//             </div>
//             <div className='right'>
//               <span className={itemStyle=='1F'?'active':''} onClick={setHref.bind(this,'1F')}>企业简介</span>
//               <span className={itemStyle=='2F'?'active':''} onClick={setHref.bind(this,'2F')}>企业文化</span>
//               <span className={itemStyle=='3F'?'active':''} onClick={setHref.bind(this,'3F')}>团队生活</span>
//             </div>
//           </div>
//         </div>
//         {/*<hr className="solid-line"></hr> */}
//         <div className="aboutus-container">
//           <div className="sec-title" id="1F">
//             <div className="text"> 企业简介/Company profile</div>
//             <div className='line'>
//               <span></span>
//               <span></span>
//               <span></span>
//             </div>
//           </div>
//           <div className="sec-text">
//             <div className="left">
//               <div className="text-title">
//                 上海心盒科技有限公司
//               </div>
//               <div className="text-paragraph">
//                 <p>
//                 上海心盒科技有限公司创立于2020年，一个来自宝洁、联合利华、亿滋、贝恩等知名企业，
//                 具有丰富的品牌操盘和咨询服务经验的团队，看到消费品牌在营销的过程中，有大量的消费者反馈数据被埋没、被流失。
//                 </p>
//                 <p>
//                 他们创造了心愿盒，一座链接品牌与消费者的桥梁，一个品牌与消费者互相尊重，互相聆听对方声音的乌托邦。
//                 </p>
//                 <p>
//                 这段旅程的开端很艰难，但是他们发现，有越来越多志同道合的品牌方、消费者、小伙伴和他们走到了一起。
//                 </p>
//                 <p>
//                 因为聆听很难得，尊重很珍贵。
//                 </p>
//                 <p>
//                 希望您能在这里实现自己的心愿，并把心愿带给到更多的人。
//                 </p>
//               </div>
//             </div>
//             <img className="right" src={CompPic} />
//           </div>
//
//           <div className="sec-title" id="2F">
//             <div className="text"> 企业文化/corporate culture</div>
//             <div className='line'>
//               <span></span>
//               <span></span>
//               <span></span>
//             </div>
//           </div>
//           <ul className="list-template culture">
//             <li className="culture-item">
//               <div className='item-bg bird'></div>
//               <div className="culture-title">
//                 企业愿景
//               </div>
//               <div className="culture-des">
//                 <p>
//                   传递每一份惊喜，聆听每一个声音
//                 </p>
//                 <p> ............</p>
//               </div>
//             </li>
//             <li className="culture-item">
//               <div className='item-bg goal'></div>
//               <div className="culture-title">
//                 企业使命
//               </div>
//               <div className="culture-des">
//                 <p>
//                   构建品牌和消费者沟通的桥梁为社会带来更多更好的产品
//                 </p>
//                 <p> ............</p>
//               </div>
//             </li>
//             <li className="culture-item">
//               <div className='item-bg compass'></div>
//               <div className="culture-title">
//                 首要原则
//               </div>
//               <div className="culture-des">
//                 <p>
//                   尽自身最大努力服务好每一个信任我们的人
//                 </p>
//                 <p> ............</p>
//               </div>
//             </li>
//             <li className="culture-item">
//               <div className='item-bg teach'></div>
//               <div className="culture-title">
//                 首要目标
//               </div>
//               <div className="culture-des">
//                 <p>
//                   成为人与品牌最好的孵化器
//                 </p>
//                 <p> ............</p>
//               </div>
//             </li>
//
//           </ul>
//
//           <div className="sec-title" id="3F">
//             <div className="text"> 团队生活</div>
//             <div className='line'>
//               <span></span>
//               <span></span>
//               <span></span>
//             </div>
//           </div>
//
//           <Slider {...settings} className="team-life">
//             <div className='team-background team_pic1'></div>
//             <div className='team-background team_pic2'></div>
//             <div className='team-background team_pic3'></div>
//             <div className='team-background team_pic1'></div>
//             <div className='team-background team_pic2'></div>
//             <div className='team-background team_pic3'></div>
//           </Slider>
//
//         </div>
//
//         </>
//     );
// }
//
// export default AboutUs
