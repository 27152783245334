import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import './NavBar.css';
import NavIcon from '../images/logo.png';
import menu from '../images/menu.png'
import phoneicon2 from '../images/phoneicon2.png'

class NavBar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      navGroup:[
        {name:'首页',link:'/'},
        {name:'客户案例',link:'/Example'},
        {name:'关于我们',link:'/AboutUs'},
        {name:'联系我们',link:'/ContactUs'}
      ],
      number:0,

      popupDisplay:'popupNone'
    };
  }

  closeMobileMenu = (index) => {
    this.setState({
      number:index
    })
  };

  setNumber(flag){
    if(window.location.href.indexOf('/Example')>0 && flag){
      this.setState({
        number:1
      })
    }else if(window.location.href.indexOf('/AboutUs')>0 && flag){
      this.setState({
        number:2
      })
    }else if(window.location.href.indexOf('/ContactUs')>0 && flag){
      this.setState({
        number:3
      })
    }else{
      this.setState({
        number:0
      })
    }
  }

  popupStateF(){
    if(this.state.popupDisplay=='popupBlock'){
      this.setState({
        popupDisplay:'popupNone'
      })
    }else{
      this.setState({
        popupDisplay:'popupBlock'
      })
    }

    this.setNumber(true)
  }

  componentDidMount(){
    this.setNumber(true)
  }

  render(){
    return(
        <nav className="navbar">
          <div className='navbar-container'>
            <Link to='/' className="navbar-logo" onClick={this.closeMobileMenu.bind(this,0)}>
              <img src={NavIcon}/>
            </Link>
            <ul className="nav-menu">
              {
                this.state.navGroup.map((item,index)=>{
                  return <li className={this.state.number == index?'nav-item active':'nav-item'} key={'nav'+index}>
                    <Link to={item.link} className={this.state.number == index?'nav-links active':'nav-links'} onClick={this.closeMobileMenu.bind(this,index)}>
                      {item.name}
                    </Link>
                  </li>
                })
              }
              <li className="nav-item">
                <a href='http://cem.xinhekeji.net' className="nav-links nav-font-style">登录/注册</a>
              </li>
            </ul>
            <div className={'phone-nav'}>
              <div className={'phone-nav-main'}>
                <img src={menu} className={'phone-nav-img'} onClick={this.popupStateF.bind(this)}/>
                <div className={this.state.popupDisplay=='popupBlock'?'popupBlock popup':'popupNone popup'} onClick={this.popupStateF.bind(this)}></div>
                <ul className={this.state.popupDisplay=='popupBlock'?'popupBlock phone-nav-list':'popupNone phone-nav-list'}>
                  {
                    this.state.navGroup.map((item,index)=>{
                      return <li className={this.state.number == index?'active':''} key={'nav'+index} onClick={this.popupStateF.bind(this)}>
                        <Link to={item.link} onClick={this.closeMobileMenu.bind(this,index)}>
                          {item.name}
                        </Link>
                      </li>
                    })
                  }
                  {/*<li className="" onClick={this.popupStateF.bind(this)}>*/}
                  {/*  <img src={phoneicon2} className={'phone2'}></img>*/}
                  {/*  <a href='http://cem.xinhekeji.net'>电话咨询</a>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </div>
          </div>
        </nav>
    )
  }
}

NavBar.propTypes = {

}

export default NavBar

