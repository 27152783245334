import React from 'react'
import './ContactUs.css';

import PropTypes from 'prop-types'
import { Spin } from 'antd';
import axios from 'axios';
import $script from 'scriptjs'
import HomeIcon from "../images/home.png";
import Address from '../images/address.png';
import Phone from '../images/phone.png';
import Email from '../images/email.png';
import mapImg from '../images/map.jpeg';
import Serve from '../images/serve.png';
import {Link} from "react-router-dom";

const googleMapSdk='https://maps.googleapis.com/maps/api/js?key=your key';
const gaodeMapSdk='https://webapi.amap.com/maps?v=1.4.2&key=your key';

let map = null;
let marker = null;
let geocoder = null;
let zoomLevel=15;


class ContactUs extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            status:0,
            itemStyle:'contactus_1F',
            name:'',
            phone:'',
            academic:'',
            companyBrand:'',
            propose:''
        };
    }
    setHref(id) {
        this.setState({
            itemStyle:id
        })
        document.documentElement.scrollTop = document.getElementById(id).offsetTop-
        document.getElementsByClassName('contactUs-nav')[0].offsetHeight -
        document.getElementsByClassName('navbar-container')[0].offsetHeight
    }

    change(key,target){
        // window.location.reload()
        // this.setState({
        //     [key]:document.getElementById(key).value
        // })
        //console.log(this.state);
    }
    submit(){
        this.state.name = document.getElementById('name').value
        this.state.phone = document.getElementById('phone').value
        this.state.academic = document.getElementById('academic').value
        this.state.companyBrand = document.getElementById('companyBrand').value
        this.state.propose = document.getElementById('propose').value

        window.location.href = "mailto:paul.xu@xinhekeji.net?subject="+"来自于"+this.state.companyBrand+"的商务咨询"+"&subject=主题&body=姓名："+this.state.name+";手机："+this.state.phone+";职称："+this.state.academic+";公司品牌："+this.state.companyBrand+";建议："+this.state.propose
        // console.log(222);
    }
    goHome(){
        window.location.reload()
    }
    componentWillMount(){
        if(!window.AMap&&!(window.google&&window.google.maps)){
            axios.get(googleMapSdk,{timeout: 1000}).then(res => {
                $script( [googleMapSdk],function(a,b){})
            }).catch(function (error) {
                $script( [gaodeMapSdk],function(a,b){})
            });
        }
    }
    componentDidMount(){
        let _this=this;
        // const [itemStyle,setItemStyle] = useState('1F');
        function listenerStorage(){
            if(window.AMap||(window.google && window.google.maps)){
                if(window.AMap) {
                    const {lat, lng, getMapAddress} = _this.props;
                    const latlngxy=[(!lng||lng=='undefined'||lng=='0')?121.465915:lng,(!lat||lat=='undefined'||lat=='0')?31.231644:lat];//默认北京天安门
                    map = new window.AMap.Map('allmap', {
                        resizeEnable: true,
                        center: latlngxy,
                        zoom: zoomLevel
                    });
                    let mapLang;
                    if(window.localStorage.getItem('i18n') == 'en_US'){
                        mapLang = 'en';
                    }else{
                        mapLang = 'zh_cn';
                    }
                    map.setLang(mapLang);
                    marker = new window.AMap.Marker({
                        map: map,
                        position: latlngxy,
                        content:'<div class="map-container"><div class="map-popup"></div><div class="img"></div><div class="name">上海心盒科技有限公司</div></div>'
                    });

                    // map.on('click',function(e){
                    //     marker.setPosition(e.lnglat);
                    //     window.AMap.service('AMap.Geocoder',function(){
                    //         geocoder = new window.AMap.Geocoder({});
                    //         geocoder.getAddress(e.lnglat,function(status,result){
                    //             if(status === 'complete' && result.info === 'OK'){
                    //                 const address=result.regeocode.formattedAddress
                    //                 getMapAddress&&getMapAddress(address);
                    //             }
                    //         })
                    //     })
                    // })
                }

                if(window.google && window.google.maps){

                    const {lat, lng, getMapAddress} = _this.props;
                    const latlngxy=[(!lng||lng=='undefined'||lng=='0')?116.397428:lng,(!lat||lat=='undefined'||lat=='0')?39.90923:lat];//默认北京天安门

                    var uluru = {lat: parseFloat(latlngxy[1]), lng: parseFloat(latlngxy[0])};

                    initMap()
                    function initMap() {
                        map = new window.google.maps.Map(document.getElementById('allmap'), {
                            resizeEnable: true,
                            center: uluru,
                            zoom: zoomLevel
                        });
                        marker = new window.google.maps.Marker({
                            map: map,
                            position: uluru,
                        });
                    }

                    map.addListener('click',function(e){
                        let latlng=e.latLng;
                        marker.setPosition(latlng);
                        geocoder = new window.google.maps.Geocoder()
                        geocoder.geocode({'location': latlng}, function(results, status) {
                            if (status === 'OK') {
                                const address=results[0].formatted_address
                                getMapAddress&&getMapAddress(address.substring(0,address.indexOf(' ')));
                            } else {
                                console.log('Geocoder failed due to: ' + status);
                            }
                        });
                    })
                }
                _this.setState({
                    status:1
                })
            }else{
                setTimeout(function(){
                    listenerStorage()
                },800)
            }
        }
        listenerStorage();
    }

    componentWillReceiveProps=(nextProps)=>{
        const {getMapPoint}=this.props;
        if(window.AMap && nextProps.address&&nextProps.address!=this.props.address) {
            window.AMap.service('AMap.Geocoder',function(){
                geocoder = new window.AMap.Geocoder({});
                geocoder.getLocation(nextProps.address, function (status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                        let latlng = result.geocodes[0].location;
                        getMapPoint&&getMapPoint(latlng);
                        let latlngxy = [latlng['lng'],latlng['lat']];
                        const currentZoom=map.getZoom();
                        map.setZoomAndCenter(currentZoom!=zoomLevel?currentZoom:zoomLevel, latlngxy);
                        marker.setPosition(latlng);
                    } else {
                        console.log('search "' + nextProps.address + '" no data')
                    }
                });
            });
        }

        if(window.google && window.google.maps && nextProps.address&&nextProps.address!=this.props.address){
            geocoder = new window.google.maps.Geocoder()
            geocoder.geocode({'address': nextProps.address}, function(results, status) {
                if (status === 'OK') {
                    let latlng = results[0].geometry.location;
                    getMapPoint&&getMapPoint({lat:latlng.lat(),lng:latlng.lng()});
                    map.setCenter(latlng);
                    marker.setPosition(latlng);
                } else {
                    console.log('Geocode was not successful for the following reason: ' + status);
                }
            });
        }
    }

    render(){
        const { height } = this.props;
        return(
            <div>
                <div style={{height:height?height:'37.5vw'}} className='displayNoneBlockPC'>
                    <Spin spinning={this.state.status==0?true:false} tip="Loading...">
                        <div id="allmap" style={{height:height?height:'37.5vw'}} >
                        </div>
                    </Spin>
                </div>
                <img src={mapImg} className='map_img displayNonePhone'/>
                <div className='contactUs-nav displayNoneBlockPC'>
                    <div className='nav-container'>
                        <div className='left'>
                            <img src={HomeIcon}/>
                            <span onClick={this.goHome}><Link to='/'>首页</Link></span>
                            <span>&gt;</span>
                            <span>联系我们</span>
                        </div>
                        <div className='right'>
                            <span className={this.state.itemStyle=='contactus_1F'?'active':''} onClick={this.setHref.bind(this,'contactus_1F')}>联系方式</span>
                            <span className={this.state.itemStyle=='contactus_2F'?'active':''} onClick={this.setHref.bind(this,'contactus_2F')}>留言板</span>
                        </div>
                    </div>
                </div>
                <div className="contactus-container">
                    <div className="sec-title" id="contactus_1F">
                        <div className="text"> 联系方式</div>
                        <div className='line displayNoneBlockPC'>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>

                    <div className="sec-title">
                        <div className='information-list'>
                            <div className='bg'><img src={Address}/></div>
                            <div className='name'>公司地址</div>
                            <div className='substr'>上海市静安区南京西路580号仲益大厦B座609</div>
                        </div>
                        <div className='information-list'>
                            <div className='bg'><img src={Phone}/></div>
                            <div className='name'>商务咨询</div>
                            <div className='substr'>13002177857 傅先生</div>
                        </div>
                        <div className='information-list'>
                            <div className='bg'><img src={Email}/></div>
                            <div className='name'>公司邮箱</div>
                            <div className='substr'>jianchen.fu@xinhekeji.net</div>
                        </div>
                        {/* <div className='information-list'>
                            <div className='bg'><img src={Serve}/></div>
                            <div className='name'>公司客服</div>
                            <div className='substr'>13651832359</div>
                        </div> */}
                    </div>

                    <div className="sec-title marginTop" id="contactus_2F">
                        <div className="text"> 留言板</div>
                        <div className='line displayNoneBlockPC'>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>

                    <div className='message-title displayNoneBlockPC'>给我们留言</div>
                    <div className='message-contain'>
                        <div className='name'>contact us</div>
                        <div className='substr'>如果您对我们的服务感兴趣，请联系我们，真诚地为您服务</div>
                        <div className='from'>
                          <div className='left'>
                              <input type='text' placeholder='姓名' onChange={this.change.bind(this,'name')} id='name' key='name'/>
                              <input type='text' placeholder='手机' onChange={this.change.bind(this,'phone')} id='phone' key='phone'/>
                              <input type='text' placeholder='职称' onChange={this.change.bind(this,'academic')} id='academic' key='academic'/>
                              <input type='text' placeholder='公司品牌' onChange={this.change.bind(this,'companyBrand')} id='companyBrand' key='companyBrand'/>
                          </div>
                          <div className='right'>
                             <textarea placeholder='您想要咨询的问题/对我们有更好的建议....' onChange={this.change.bind(this,'propose')} id='propose'></textarea>
                          </div>
                        </div>
                    </div>

                    <div className="btn"><a onClick={this.submit.bind(this,'submit')}>提交</a></div>

                </div>
            </div>
        )
    }
}

ContactUs.propTypes = {
    lng: PropTypes.string,
    lat: PropTypes.string,
    className: PropTypes.string,
}

export default ContactUs


// function ContactUs() {
//     return (
//         <div>
//             dfd
//         </div>
//     )
// }
//
// export default ContactUs
